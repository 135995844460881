import React from 'react'
import Img from 'gatsby-image'
import styles from './image-carousel.module.css'
import { CSSTransitionGroup } from 'react-transition-group'
import { addCloudinaryTransformPreset } from '../lib/util'

const transitionDuration = 1100

export default class ImageCarousel extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      imageIndex: 0,
      enteringIndices: [],
      exitingIndices: [],
    }
  }

  componentDidMount() {
    const { images } = this.props
    setInterval(() => {
      const { enteringIndices, exitingIndices } = this.state
      const currentIndex = this.state.imageIndex
      const nextIndex = ((this.state.imageIndex + 1) % images.length)

      this.setState({ 
        imageIndex: nextIndex,
        enteringIndices: [...enteringIndices, nextIndex],
        exitingIndices: [...exitingIndices, currentIndex] 
      })

      // Stopping the animation
      setTimeout(() => {
        this.setState({
          enteringIndices: this.state.enteringIndices.filter(i => i != nextIndex),
          exitingIndices: this.state.exitingIndices.filter(i => i != currentIndex)
        })
      }, transitionDuration)

    }, 5000)
  }

  getAnimationClassName(index) {
    const { enteringIndices, exitingIndices } = this.state 
    if(enteringIndices.indexOf(index) > -1) {
      return styles.animationEnter
    } else if(exitingIndices.indexOf(index) > -1) {
      return styles.animationExit
    }
  }

  render() {
    const { images, onImageClicked } = this.props
    const { imageIndex, enteringIndices, exitingIndices } = this.state
    console.log(images)
    return (
      <div>
        {images.map((image, index) => {

          if((index === imageIndex) || (enteringIndices.indexOf(index) > -1) ||  (exitingIndices.indexOf(index) > -1)) {
            return (
              <div key={index} 
                className={this.getAnimationClassName(index)} 
                onClick={e => {
                  if(onImageClicked)
                    onImageClicked(images[imageIndex], imageIndex)
                }}>
                {images[index].fluid &&
                  <Img
                    fluid={images[index].fluid}
                    className={styles.imageCarousel}
                    />
                }
                {images[index].secure_url &&
                  <img
                    src={addCloudinaryTransformPreset(images[index].secure_url, 'carousel')}
                    className={styles.imageCarousel}
                    />
                }

                
              </div>
            )
          } else {
            return <div key={index}></div>
          }
          
        })}
        
      </div>
    )
  }

}