import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Header from '../components/header'
import Footer from '../components/footer'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Navigation from '../components/navigation'
import ImageCarousel from '../components/image-carousel'
import NewsSection from '../components/news-section'

class NotFound extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    return (
      <div>
        <Helmet title={siteTitle} />
        <Header data={author.node} />
        
        <div className="wrapper" style={{ textAlign: 'center' }}>
          <h2>Page Not Found</h2>
          <Link to="/">Home</Link>
        </div>

        <Footer data={author.node} />
      </div>
    )
  }
}

export default NotFound

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        image
      }
    }

    allContentfulPerson(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          instagram
          email
        }
      }
    }
  }
`
